<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard class="topCard">
          <CCardHeader class="topCardHeader">
            <div class="d-flex justify-content-between align-items-center">
              <div class="h4 m-0">Test Kullanıcıları</div>
              <div class="d-flex justify-content-end" style="min-width: 550px">
                <input
                  type="text"
                  v-model="searchFilterInput"
                  autocomplete="off"
                  class="form-control mr-3"
                  style="max-width: 250px"
                  placeholder="Arama..."
                  v-on:keyup="onFilterTextBoxChanged()"
                />
                <RoleProvider slug="TESTER_CREATE">
                  <div slot-scope="{ canItPass }">
                    <CButton
                      v-if="canItPass"
                      color="light"
                      variant="outline"
                      size="sm"
                      @click="
                        (adminModalAddUpdate.process = 'post'),
                          (adminModalAddUpdate.title =
                            'Yeni Test Kullanıcısı Ekle'),
                          (adminModalAddUpdate.modal = true)
                      "
                      class="py-2 px-3 font-weight-bold"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'plus']"
                        class="mr-2"
                      />Yeni Ekle
                    </CButton>
                  </div>
                </RoleProvider>
              </div>
            </div>
          </CCardHeader>

          <CCardBody class="p-0">
            <!-- :sendToClipboard="sendToClipboard" -->
            <ag-grid-vue
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-balham fp-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :sideBar="sideBar"
              :rowSelection="rowSelection"
              :enableRangeSelection="true"
              :statusBar="statusBar"
              @rowDoubleClicked="onRowDoubleClicked"
              @grid-ready="onGridReady"
            ></ag-grid-vue>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- :closeOnBackdrop="false" -->
    <CModal
      :title="adminModalAddUpdate.title"
      size="lg"
      :closeOnBackdrop="false"
      :show.sync="adminModalAddUpdate.modal"
    >
      <CRow>
        <CCol md="12">
          <CInput
            type="text"
            label="Kullanıcı Adı"
            autocomplete="username"
            placeholder="Kullanıcı adını giriniz"
            :disabled="adminModalAddUpdate.process === 'put'"
            v-model="adminModalAddUpdate.form.userName"
          />
        </CCol>

        <CCol md="12">
          <CInput
            type="password"
            label="Şifre"
            placeholder="Şifre girin"
            :disabled="adminModalAddUpdate.process === 'put'"
            autocomplete="new-password"
            v-model="adminModalAddUpdate.form.password"
          />
        </CCol>
      </CRow>
      <template #footer>
        <RoleProvider slug="TESTER_CREATE">
          <div slot-scope="{ canItPass }">
            <CButton
              v-if="adminModalAddUpdate.process === 'post' && canItPass"
              color="success"
              :disabled="adminModalAddUpdate.btnDisable"
              @click="adminAddUpdate()"
              >Ekle</CButton
            >
          </div>
        </RoleProvider>
        <RoleProvider slug="TESTER_DELETE">
          <div slot-scope="{ canItPass }">
            <CButton
              v-if="adminModalAddUpdate.process === 'put' && canItPass"
              color="danger"
              :disabled="adminModalAddUpdate.btnDisable"
              @click="adminDelete(adminModalAddUpdate.form)"
              >Sil</CButton
            >
          </div>
        </RoleProvider>
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { AgGridVue } from "ag-grid-vue";
import VueSweetalert2 from "vue-sweetalert2";
import moment from "moment";
import { RoleProvider } from "../../provider";

Vue.use(VueSweetalert2);

export default {
  name: "Admins",
  components: {
    RoleProvider,
    AgGridVue,
  },
  data() {
    return {
      searchFilterInput: "",
      // AG GRID
      columnDefs: [
        // Grid sütünların dizisi
        {
          field: "userName",
          headerName: "Kullanıcı Adı",
        },
        {
          field: "registered",
          cellRenderer: (params) => {
            return moment(new Date(params.value)).format("DD.MM.YYYY - HH:mm");
          },
          headerName: "Kayıt Tarihi",
        },
      ],
      defaultColDef: {
        // Varsayılan bir sütun tanımı
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowData: [], // Verilerin ekleneceği dizi
      sideBar: {
        // Grid yan çubuk tanımlaması
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          ,
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      rowSelection: "multiple", // Satır Seçim Türü
      statusBar: {
        // Durum çubuğunda kullanılacak bileşenler
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
            text: "asdas",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },

      // YÖNETİCİ EKLEME GÜNCELLEME MODAL
      adminModalAddUpdate: {
        modal: false,
        title: "",
        process: "",
        btnDisable: false,

        form: {
          _id: "",
          userName: "",
          password: "",
        },
      },
    };
  },
  created() {
    this.getAdmins();
  },
  methods: {
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
    },

    onGridReady(params) {
      // Grid ready olduğunda
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      params.api.sizeColumnsToFit();
    },

    getAdmins() {
      this.$Progress.start();

      axios
        .get(process.env.VUE_APP_API_URL + "admin/testers")
        .then((response) => {
          this.$Progress.finish();
          this.rowData = response.data.adminList;
        });
    },
    adminAddUpdate() {
      let params = {
        _id: this.adminModalAddUpdate.form._id,
        userName: this.adminModalAddUpdate.form.userName,
        password: this.adminModalAddUpdate.form.password,
      };

      this.$Progress.start();
      this.adminModalAddUpdate.btnDisable = true; // Modal'daki post button'u disable ettik.

      axios[this.adminModalAddUpdate.process](
        process.env.VUE_APP_API_URL + "admin/testers",
        params,
        { withCredentials: true }
      ).then((response) => {
        this.$Progress.finish();
        this.adminModalAddUpdate.btnDisable = false; // Modal'daki post button'u enable ettik.

        if (response.data.result === "success") {
          this.getAdmins();
          this.adminModalAddUpdate.modal = false; // Modal'ı kapattık

          Vue.swal({
            icon: "success",
            title:
              this.adminModalAddUpdate.process === "post"
                ? "<strong>" +
                  params.name +
                  " " +
                  params.lastname +
                  "</strong> isimli testci başarıyla eklendi."
                : "<strong>" +
                  params.name +
                  " " +
                  params.lastname +
                  "</strong> isimli testci başarıyla düzenlendi.",
            toast: true,
            position: "bottom-start",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Vue.swal.stopTimer);
              toast.addEventListener("mouseleave", Vue.swal.resumeTimer);
            },
          });
        } else {
          switch (response.data.errCode) {
            case "USERNAME_IS_REGISTERED":
              Vue.swal({
                icon: "error",
                title: "Yeni Test Kullanıcısını Ekle",
                html:
                  "<strong>" +
                  response.data.username +
                  "</strong> kullanıcı adı zaten kayıtlı!",
                confirmButtonText: "Tamam",
              });
              break;
            case "COULD_NOT_ADD_ADMIN":
              Vue.swal({
                icon: "error",
                title: "Yeni Test Kullanıcısını Ekle",
                html: "Test Kullanıcısını eklenemedi!",
                confirmButtonText: "Tamam",
              });
              break;
          }
        }
      });
    },
    adminDelete() {
      Vue.swal
        .fire({
          icon: "question",
          text: "Test kullanıcısını silmek istiyor musunuz?",
          showCancelButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
          showCloseButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            let params = {
              _id: this.adminModalAddUpdate.form._id,
            };

            axios
              .delete(
                process.env.VUE_APP_API_URL + "admin/testers/" + params._id,
                {
                  withCredentials: true,
                }
              )
              .then((response) => {
                if (response.data.result === "success") {
                  this.getAdmins();

                  this.adminModalAddUpdate.modal = false;
                } else {
                  switch (response.data.errCode) {
                    case "UPDATE_ADMIN_INFO_FAILED":
                      Vue.swal({
                        icon: "error",
                        title: "Test Kullanıcısını Sil",
                        html: "Test Kullanıcısını silinemedi!",
                        confirmButtonText: "Tamam",
                      });
                      break;
                  }
                }
              });
          }
        });
    },

    onRowDoubleClicked(params) {
      var _this = this;

      _this.adminModalAddUpdate.process = "put";
      _this.adminModalAddUpdate.title = "Test Kullanıcısını Düzenle";

      _this.adminModalAddUpdate.form._id = params.node.data._id;
      _this.adminModalAddUpdate.form.password = params.node.data.password;
      _this.adminModalAddUpdate.form.userName = params.node.data.userName;

      _this.adminModalAddUpdate.modal = true;
    },
  },
  watch: {
    "adminModalAddUpdate.modal": function (val) {
      if (val && this.adminModalAddUpdate.process == "post") {
        this.adminModalAddUpdate.form._id = "";
        this.adminModalAddUpdate.form.userName = "";
        this.adminModalAddUpdate.form.password = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn-collapse {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;

  &:hover {
    cursor: pointer;
  }
}
</style>
